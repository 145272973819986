// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-how-tsx": () => import("./../../../src/pages/how.tsx" /* webpackChunkName: "component---src-pages-how-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-join-tsx": () => import("./../../../src/pages/join.tsx" /* webpackChunkName: "component---src-pages-join-tsx" */),
  "component---src-pages-match-tsx": () => import("./../../../src/pages/match.tsx" /* webpackChunkName: "component---src-pages-match-tsx" */),
  "component---src-pages-nomatch-tsx": () => import("./../../../src/pages/nomatch.tsx" /* webpackChunkName: "component---src-pages-nomatch-tsx" */),
  "component---src-pages-play-tsx": () => import("./../../../src/pages/play.tsx" /* webpackChunkName: "component---src-pages-play-tsx" */)
}

